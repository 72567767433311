import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import { FeatureSliderWrapper } from './cryptoSlides.style';

const FeatureSlider = ({ row, title, description, sectionSubTitle }) => {
  return (
    <FeatureSliderWrapper
      id="featureslider"
      style={
        {
          /* position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)' */
        }
      }
    >
      <Container noGutter mobileGutter>
        <Box className="row" {...row}>
          <Text content="Best Finance cash offer" {...title} />
          <FeatureBlock
            title={
              <Heading
                content="What is a Best Finance cash offer?"
                {...title}
              />
            }
            description={
              <Text content="1. GET YOUR PRE-APPROVAL" {...description} />
            }
          />
          <Text
            content="• Complete your mortgage application with our online platform"
            {...description}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// Transactions style props
FeatureSlider.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
};

// Trusted default style
FeatureSlider.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: 'center',
  },
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: 'center',
  },
  sectionSubTitle: {
    as: 'span',
    fontSize: ['16px', '16px', '30px', '30px', '30px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
    textAlign: 'center',
  },
};

export default FeatureSlider;
